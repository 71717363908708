import React, { useEffect, useState } from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { Button, Col, Row, Select, Table } from 'antd';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { DIMENSION_STATUSES_CODES } from '../../../../constants';
import { showError, showSuccess } from '../../../../utils/alerts';
import { get, post, put, remove } from '../../../../utils/backend';
import { addOrReplace, cloneDeep } from '../../../../utils/helpers';
import DimensionSelector from '../../../DimensionSelector';
import Search from '../../../Search';

const { Option } = Select;

const ReportAccess = React.memo(
  ({
    surveys,
    selectedSurveys,
    setSelectedSurveys,
    dimensions,
    runValidation,
    setReportAccessLoading,
  }) => {
    const [searchText, setSearchText] = useState('');
    const [searchTextDropdown, setSearchTextDropdown] = useState('');
    const [allSurveys, setAllSurveys] = useState([]);
    const [dimensionValues, setDimensionValues] = useState([]);
    const [threshold, setThreshold] = useState(-1);
    const [surveyOptions, setSurveyOptions] = useState([]);
    const [dataLoadingPromicesQty, setDataLoadingPromicesQty] = useState(0);

    useEffect(() => {
      setReportAccessLoading(dataLoadingPromicesQty > 0);
    }, [dataLoadingPromicesQty]);

    useEffect(async () => {
      try {
        increaseActivePromiceQty();
        const defaultProps = await get('/organizations/org_default_properties');
        const privacyData = defaultProps.find((item) => item.code === 'heat_map_threshold') || {};
        setThreshold(parseInt(privacyData.value, 10));
      } catch (e) {
        showError(e.message);
      } finally {
        decreaseActivePromiceQty();
      }
    }, []);

    useEffect(async () => {
      increaseActivePromiceQty();
      try {
        const surveysResponse = await get('/surveys');
        if (surveysResponse.length) {
          setAllSurveys(surveysResponse?.filter((survey) => survey.type !== 'comm'));
        }
      } catch (e) {
        showError(e.message);
      } finally {
        decreaseActivePromiceQty();
      }
    }, []);

    useEffect(() => {
      const options = allSurveys.map((survey) => ({
        label: survey.surveyname,
        value: survey.id,
      }));
      if (searchTextDropdown.length) {
        setSurveyOptions(
          options.filter((item) =>
            item.label.toLowerCase().includes(searchTextDropdown.toLowerCase()),
          ),
        );
      } else {
        setSurveyOptions(options);
      }
    }, [allSurveys, searchTextDropdown]);

    function increaseActivePromiceQty() {
      setDataLoadingPromicesQty((prev) => ++prev);
    }
    function decreaseActivePromiceQty() {
      setDataLoadingPromicesQty((prev) => --prev);
    }

    function dropdownRender(menu) {
      return (
        <div className="user-modal__surveys-dropdown">
          <div className="user-modal__surveys-dropdown__search">
            <Search
              value={searchTextDropdown}
              onChange={setSearchTextDropdown}
              placeholder="Search surveys"
            />
          </div>
          {menu}
        </div>
      );
    }

    function onSurveyDeselect(value) {
      setSelectedSurveys((prevList) => prevList.filter((item) => item.surveyId !== value));
    }

    function onSurveySelect(values) {
      const emptySurveyItem = (surveyId) => {
        return {
          surveyId,
          fullReportAccess: false,
          dimensionList: [],
        };
      };
      const selectedSurveysIds = _.map(selectedSurveys, 'surveyId');
      if (values.length > selectedSurveysIds.length) {
        const notSelectedSurveysIds = _.difference(values, selectedSurveysIds);
        if (notSelectedSurveysIds.length) {
          notSelectedSurveysIds.forEach((surveyId) => {
            setSelectedSurveys((prev) => [emptySurveyItem(surveyId), ...prev]);
          });
        }
      } else {
        setSelectedSurveys(selectedSurveys.filter((survey) => values.includes(survey.surveyId)));
      }
    }

    useEffect(async () => {
      const unConfidentialDimensions = dimensions.filter(
        (dimension) => dimension.status !== DIMENSION_STATUSES_CODES.CONFIDENTIAL,
      );
      const promices = unConfidentialDimensions.map((dimension) =>
        post(`/dimension_metadata/${dimension.id}/dimensions_values`, []),
      );

      try {
        const responses = await Promise.all(promices);
        if (responses) {
          const rawValues = responses.flat();
          setDimensionValues([
            {
              value: 'fullReportAccess',
              label: 'All Survey Results',
            },
            ...unConfidentialDimensions.flatMap((dimension) => {
              const values = _.filter(rawValues, { dimension_code: dimension.dimension_code });
              if (values.length) {
                return [
                  {
                    value: dimension.dimension_code,
                    label: dimension.display_name,
                    children: values
                      .map((v) => ({
                        value: v.value,
                        label: v.value,
                        disableCheckbox: v.userCount < threshold,
                      }))
                      .sort((a, b) => a.disableCheckbox - b.disableCheckbox),
                  },
                ];
              }
              return [];
            }),
          ]);
        }
      } catch (error) {
        showError(error.message);
      }
    }, [dimensions]);

    function prepareUsersWithDimensions(data) {
      if (!data.length) {
        return [{ dimensionList: [], fullReportAccess: false }];
      }
      if (data.some((d) => d[0] === 'fullReportAccess')) {
        return [{ dimensionList: [], fullReportAccess: true }];
      }
      const dimensionList = _.uniq(data.map(_.first)).flatMap((dn) => {
        const dimValues = data.filter((d) => d[0] === dn).map(_.last);
        return dimValues.length ? [{ code: dn, values: dimValues }] : [];
      });
      return [{ dimensionList, fullReportAccess: false }];
    }

    async function onValuesChange(val, data, surveyId) {
      const user = _.assign(
        _.find(selectedSurveys, { surveyId }),
        ...prepareUsersWithDimensions(val),
      );
      if (val.length) {
        setSelectedSurveys((prev) => addOrReplace(prev, user, 'surveyId'));
      } else {
        setSelectedSurveys((prev) => addOrReplace(prev, user, 'surveyId'));
      }
    }

    const columns = [
      {
        title: 'Surveys',
        dataIndex: 'surveyId',
        key: 'surveyId',
        width: '40%',
        defaultSortOrder: 'ascend',
        sorter: (a, b) => {
          const aSurveyName = allSurveys.find((as) => as.id === a.surveyId)?.surveyname;
          const bSurveyName = allSurveys.find((as) => as.id === b.surveyId)?.surveyname;
          if (
            (!a.dimensionList.length && !a.fullReportAccess) ||
            (!b.dimensionList.length && !b.fullReportAccess)
          ) {
            return 0;
          }

          return aSurveyName?.trim().localeCompare(bSurveyName?.trim());
        },
        render: (surveyId) => allSurveys.find((as) => as.id === surveyId)?.surveyname,
      },
      {
        title: 'Report Access',
        dataIndex: 'dimensionList',
        key: 'dimensionList',
        width: '50%',
        render: (val, item) => (
          <DimensionSelector
            options={dimensionValues.map((d) => ({ ...d, userId: item.userId }))}
            values={
              dimensionValues.length
                ? item.fullReportAccess
                  ? ['fullReportAccess']
                  : val.map((v) => v.values.map((rVal) => [v.code, rVal])).flat()
                : undefined
            }
            onChange={onValuesChange}
            userId={item.surveyId}
            runValidation={runValidation}
          />
        ),
      },
      {
        title: 'Actions',
        dataIndex: 'id',
        key: 'actions',
        align: 'center',
        width: '10%',
        render: (id, item) => (
          <Button
            icon={<DeleteOutlined />}
            size="small"
            onClick={() => onSurveyDeselect(item.surveyId)}
          />
        ),
      },
    ];

    return (
      <div className="user-modal__access">
        <Row gutter={[24, 4]}>
          <Col xs={24}>Surveys:</Col>
          <Col xs={24}>
            <Select
              style={{ width: 400 }}
              value={
                surveyOptions?.length ? selectedSurveys.map((item) => item.surveyId) : undefined
              }
              mode="multiple"
              dropdownRender={dropdownRender}
              showSearch={false}
              onChange={onSurveySelect}
              options={surveyOptions}
              onDropdownVisibleChange={(isOpen) => {
                if (!isOpen) setSearchTextDropdown('');
              }}
            />
          </Col>
          <Col xs={24}>Select the surveys for which you want to provide access.</Col>
        </Row>
        <Row gutter={[8, 8]} style={{ marginTop: 25 }}>
          <Col xs={24}>
            <Table
              size="small"
              dataSource={selectedSurveys}
              columns={columns}
              rowKey="id"
              showSorterTooltip={false}
              pagination={false}
            />
          </Col>
        </Row>
      </div>
    );
  },
);

const surveyShape = PropTypes.shape({
  id: PropTypes.number,
  surveyname: PropTypes.string,
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.name,
    }),
  ),
});

ReportAccess.defaultProps = {
  surveys: [],
  selectedSurveys: [],
  setSelectedSurveys: () => {},
  setReportAccessLoading: () => {},
  dimensions: [],
};

ReportAccess.propTypes = {
  surveys: PropTypes.arrayOf(surveyShape),
  selectedSurveys: PropTypes.arrayOf(surveyShape),
  setSelectedSurveys: PropTypes.func,
  setReportAccessLoading: PropTypes.func,
  dimensions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      display_name: PropTypes.string,
      dimension_code: PropTypes.string,
    }),
  ),
  runValidation: PropTypes.bool.isRequired,
};

export default ReportAccess;
