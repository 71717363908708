import { memo } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import {
  Alignment,
  BlockQuote,
  Bold,
  ButtonView,
  ClassicEditor,
  createDropdown,
  Essentials,
  Font,
  Heading,
  Italic,
  Link,
  List,
  ListItemView,
  ListView,
  Mention,
  Paragraph,
  SourceEditing,
} from 'ckeditor5';
import { noop } from 'lodash';

import Loader from '../Loader';

import 'ckeditor5/ckeditor5.css';
import './styles.scss';

const specialsMap = {
  ENTROMY_LOGIN: {
    content: '@EntromyLogin',
    descr: 'Link to Entromy platform with streamlined login for new users',
    name: 'Entromy login',
  },
  ENTROMY_URL: {
    content: '@EntromyLink',
    descr: 'Link to Entromy platform server',
    name: 'Entromy url',
  },
  ONDEMAND_LINK: {
    content: '@OnDemandLink',
    descr: '',
    name: 'On-Demand survey',
  },
  PRIVACY_LINK: {
    content: '@PrivacyLink',
    descr: 'Link to Entromy privacy page',
    name: 'Privacy link',
  },
  SURVEY_END_DATE: {
    content: '@SurveyEndDate',
    descr: 'Survey end date',
    name: 'Survey end date',
  },
  SURVEY_LINK: {
    content: '@SurveyLink',
    descr: 'Will be replaced by a unique link to the survey for each user',
    name: 'Survey link',
  },
  USER_NAME: {
    content: '@FirstName',
    descr: 'Will dynamically replace with first name of each recipient, e.g. Dear Peter',
    name: 'User name',
  },
};

type SpecialsMapKey = keyof typeof specialsMap;

type TextEditorProps = {
  onChange: (data: string) => void;
  readOnly: boolean;
  initialValue: string;
  specials: SpecialsMapKey[];
  loading: boolean;
};

const TextEditor: React.FC<TextEditorProps> = memo(
  ({ onChange = noop, readOnly = false, initialValue = '', specials = [], loading = false }) => {
    const addSpecials = (editor: ClassicEditor): void => {
      if (!specials.length) return;
      const customDropdownView = createDropdown(editor.locale);
      customDropdownView.buttonView.extendTemplate({
        attributes: {
          class: 'custom-dropdown-no-arrow',
        },
      });
      customDropdownView.buttonView.set({
        label: 'Special',
        tooltip: true,
        withText: true,
      });
      customDropdownView.buttonView.extendTemplate({
        attributes: {
          class: 'ck-dropdown__no-arrow',
        },
      });
      const listView = new ListView(editor.locale);
      const items = specials.map((key) => {
        const item = specialsMap[key];
        const buttonView = new ButtonView();
        buttonView.set({
          label: item.name,
          withText: true,
        });
        buttonView.on('execute', () => {
          editor.model.change((writer) => {
            const insertPosition = editor.model.document.selection.getFirstPosition();
            editor.model.insertContent(writer.createText(item.content), insertPosition);
          });
        });
        const listItemView = new ListItemView(editor.locale);
        listItemView.children.add(buttonView);
        return listItemView;
      });
      listView.items.addMany(items);
      customDropdownView.panelView.children.add(listView);
      customDropdownView.render();
      editor.ui.componentFactory.add('customDropdown', () => customDropdownView);
      const toolbar = editor.ui.view.toolbar;
      toolbar.items.add(customDropdownView);
    };

    return (
      <div className="relative">
        {loading && <Loader />}
        {!loading && (
          <CKEditor
            editor={ClassicEditor}
            data={initialValue}
            onChange={(event, editor) => {
              onChange(editor.getData());
            }}
            config={{
              fontSize: {
                options: [10, 12, 14, 16, 18, 20],
              },
              plugins: [
                Bold,
                Essentials,
                Italic,
                Mention,
                Paragraph,
                List,
                Alignment,
                Font,
                Link,
                BlockQuote,
                SourceEditing,
                Heading,
              ],
              toolbar: {
                items: [
                  'undo',
                  'redo',
                  '|',
                  'bold',
                  'italic',
                  '|',
                  'numberedList',
                  'bulletedList',
                  '|',
                  'alignment:left',
                  'alignment:center',
                  'alignment:right',
                  'alignment:justify',
                  '-',
                  'heading',
                  'fontSize',
                  'fontFamily',
                  'link',
                  'blockQuote',
                  'sourceEditing',
                ],
                shouldNotGroupWhenFull: true,
              },
            }}
            disabled={readOnly}
            onReady={(editor: ClassicEditor) => {
              addSpecials(editor);
            }}
          />
        )}
      </div>
    );
  },
);

export default TextEditor;
