import { Spin } from 'antd';

import './styles.scss';

const Loader = () => (
  <div className="loader-wrapper">
    <Spin size="large" />
  </div>
);

export default Loader;
